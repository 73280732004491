import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/personal-training-matt-venner',
    name: 'personal training',
    component: () => import(/* webpackChunkName: "personal training" */ '../views/PersonalTrainingView.vue')
  },
  {
    path: '/group-outdoor-fitness',
    name: 'group outdoor fitness',
    component: () => import(/* webpackChunkName: "group outdoor fitness" */ '../views/GroupOutdoorFitnessView.vue')
  },
  {
    path: '/boxercise-chislehurst-2',
    name: 'boxercise',
    component: () => import(/* webpackChunkName: "boxercise" */ '../views/BoxerciseView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutMattView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/BlogView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "book" */ '../views/BookView.vue')
  },
  {
    path: '/reset_user_password/:id',
    name: 'password',
    component: () => import(/* webpackChunkName: "password" */ '../views/PasswordView.vue')
  },
  {
    path: '/offers/:id',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offers" */ '../views/OffersView.vue')
  },
  {
    path: '/contact-me',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contaact" */ '../views/ContactView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyView.vue')
  },
  {
    path: '/admin/',
    name: 'admin home',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminHome" */ '../views/admin/HomeView.vue')
  },
  {
    path: '/admin/login',
    name: 'admin login',
    component: () => import(/* webpackChunkName: "adminLogin" */ '../views/admin/LoginView.vue')
  },
  {
    path: '/admin/users',
    name: 'users',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminUsers" */ '../views/admin/UsersView.vue')
  },
  {
    path: '/admin/analytics',
    name: 'analytics',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminAnalytics" */ '../views/admin/AnalyticsView.vue')
  },
  {
    path: '/admin/times',
    name: 'times',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminTimes" */ '../views/admin/TimesView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not found',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NoPage.vue')
  },

  // blog routes
  {
    path: '/does-walking-10000-steps-a-day-keep-the-doctor-at-bay',
    name: 'does walking 10000 steps a day keep the doctor at bay',
    component: () => import(/* webpackChunkName: "blog1" */ '../views/blog/DoesWalking10000StepsADayKeepTheDoctorAtBay.vue')
  },
  {
    path: '/nutrition-advice-from-a-non-expert',
    name: 'nutrition advice from a non expert',
    component: () => import(/* webpackChunkName: "blog2" */ '../views/blog/NutritionAdviceFromANonExpert.vue')
  },
  {
    path: '/personal-trainer-myths-debunkedand-some-truths-about-matt-v-your-chislehurst-fitness-coach',
    name: 'personal trainer myths debunked and some truths about matt v your chislehurst fitness coach',
    component: () => import(/* webpackChunkName: "blog3" */ '../views/blog/PersonalTrainerMythsDebunkedAndSomeTruthsAboutMattVYourChislehurstFitnessCoach.vue')
  },
  {
    path: '/social-media-power-hour-visit-chislehurst',
    name: 'social media power hour visit chislehurst',
    component: () => import(/* webpackChunkName: "blog4" */ '../views/blog/SocialMediaPowerHourVisitChislehurst.vue')
  },
  {
    path: '/local-businesses-join-forces-to-launch-chislehurst-fitness',
    name: 'local businesses join forces to launch chislehurst fitness',
    component: () => import(/* webpackChunkName: "blog5" */ '../views/blog/LocalBusinessesJoinForcesToLaunchChislehurstFitness.vue')
  },
  {
    path: '/feel-covid-19-secure-at-chislehurst-fitness',
    name: 'feel covid 19 secure at chislehurst fitness',
    component: () => import(/* webpackChunkName: "blog6" */ '../views/blog/FeelCovid19SecureAtChislehurstFitness.vue')
  },
  {
    path: '/specialist-fitness-instructors-at-chislehurst-fitness',
    name: 'specialist fitness instructors at chislehurst fitness',
    component: () => import(/* webpackChunkName: "blog7" */ '../views/blog/SpecialistFitnessInstructorsAtChislehurstFitness.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.userSession) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!sessionStorage.adminSession) {
      next({ name: 'admin login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
