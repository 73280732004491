<template>
  <nav v-if="!admin && mobileNav">
    <div id="mobileNavBar">
      <img @click="() => { this.$router.push('/'); this.showNav = false }" src="@/assets/logo.png" alt="logo" />
      <i class="fa fa-bars" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-times" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div class="mobileNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/personal-training-matt-venner">Personal Training</router-link>
      <router-link @click="showNav = false" to="/group-outdoor-fitness">Group Outdoor Fitness</router-link>
      <router-link @click="showNav = false" to="/boxercise-chislehurst-2">Boxercise</router-link>
      <router-link @click="showNav = false" to="/about">About Matt</router-link>
      <router-link @click="showNav = false" to="/blog">Blog</router-link>
      <router-link @click="showNav = false" class="book" to="/book">Book</router-link>
      <router-link @click="showNav = false" class="join" v-if="!this.authed" to="/login">Join</router-link>
      <router-link @click="showNav = false" v-if="this.authed" to="/profile">My Account</router-link>
    </div>
  </nav>
  <nav v-if="!admin && nav">
    <img id="floatImg" @click="this.$router.push('/')" src="@/assets/logo.png" alt="logo" />
    <router-link to="/personal-training-matt-venner">Personal Training</router-link>
    <router-link to="/group-outdoor-fitness">Group Outdoor Fitness</router-link>
    <router-link to="/boxercise-chislehurst-2">Boxercise</router-link>
    <router-link to="/about">About Matt</router-link>
    <router-link to="/blog">Blog</router-link>
    <router-link class="book" to="/book">Book</router-link>
    <router-link class="join" v-if="!this.authed" to="/login">Join</router-link>
    <router-link v-if="this.authed" to="/profile">My Account</router-link>
  </nav>
  <nav v-if="admin && nav" id="adminDesktopNav">
    <router-link to="/admin"><img src="@/assets/logo.png" alt="logo" /></router-link>
    <router-link to="/admin/times"><i class="fa fa-calendar-week"></i></router-link>
    <router-link to="/admin/analytics"><i class="fa fa-chart-line"></i></router-link>
    <router-link to="/admin/users"><i class="fa fa-users"></i></router-link>
    <a v-if="authed" href="/" id="logout"><i class="fa-solid fa-right-from-bracket"></i></a>
  </nav>
  <nav v-if="admin && mobileNav" id="adminMobileNav">
    <div id="mobileAdminNavBar">
      <i class="fa fa-caret-down" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-caret-up" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div id="mobileAdminNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/admin"><img src="@/assets/logo.png" alt="logo" /></router-link>
      <router-link @click="showNav = false" to="/admin/times"><i class="fa fa-calendar-week"></i></router-link>
      <router-link @click="showNav = false" to="/admin/analytics"><i class="fa fa-chart-line"></i></router-link>
      <router-link @click="showNav = false" to="/admin/users"><i class="fa fa-users"></i></router-link>
    </div>
  </nav>
  <router-view />

  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      authed: false,
      admin: false,
      nav: true,
      email: localStorage.email,
      mobileNav: false,
      showNav: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('userSession');
      localStorage.removeItem('operator');
      localStorage.removeItem('admin');
      this.$router.push('/login');
      this.authed = false;
      this.operator = false;
      this.admin = false;
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      }
    }
  },
  mounted() {
    if (window.location.pathname.split('/')[1] == 'admin') {
      this.admin = true;
      document.getElementsByTagName('body')[0].classList.add('admin');
      if (localStorage.getItem('adminSession')) this.authed = true;
      else this.$router.push('/admin/login');
    } else {
      document.getElementsByTagName('body')[0].classList.add('main');
      if (localStorage.getItem('userSession')) this.authed = true;
    }

    this.setSize();
    window.addEventListener('resize', this.setSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.main {
  #app {
    // Roboto font
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;

    nav {
      position: relative;
      width: 100%;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 5%;
      box-sizing: border-box;
      font-size: 1.1rem;
      z-index: 10;

      img {
        position: absolute;
        left: 5%;
        max-width: 200px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: $text;
        font-size: 15px;
        margin: 0 20px;
        padding: 20px 0;

        &.router-link-exact-active {
          color: $colourP;
        }

        &.book, &.join {
          font-weight: bold;
        }

        &:hover {
          color: $colourP;
        }
      }
    }
  }
}

.admin {
  #app {
    position: fixed;
    top: 0;
    left: 90px;
    width: calc(100% - 90px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: #272a2c;

    h1 {
      color: $colourP;
    }

    button, select, option, input {
      font-family: inherit;
      font-size: inherit;
    }
  }

  #adminDesktopNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    padding: 20px;
    background-color: #1b1e1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      font-weight: bold;
      text-decoration: none;
      color: white;
      display: block;
      width: 70px;
      height: 70px;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      transition: background-color 0.1s ease-in-out;
      font-size: 25px;

      &.router-link-exact-active {
        background-color: #58595B;
      }

      img {
        width: 30px;
      }
    }

    #logout {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    #app {
      left: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  #app {
    left: 0;
    width: 100%;
  }

  .main #app nav {
    height: 70px;
  }

  #openNav,
  #closeNav {
    position: absolute;
    right: 20px;
    opacity: 0;
    rotate: 90deg;
    transition: 0.2s;
    background-color: $colourP;
    border-radius: 2px;
    cursor: pointer;
  }

  #closeNav {
    rotate: -90deg;
  }

  #openNav.active,
  #closeNav.active {
    z-index: 3;
    opacity: 1;
    rotate: 0deg;
  }

  #mobileNavBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    box-sizing: border-box;
    font-size: 1.1rem;
    z-index: 10;

    img {
      height: 45px;
    }

    i {
      font-size: 20px;
      padding: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .mobileNavDropdown {
    height: 0px;
    width: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.2s;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    a {
      margin-top: 10px !important;
      margin-left: 40px !important;
      font-size: 1.5rem;
      color: white !important;
      width: fit-content;
    }
  }

  .dropdownOpen {
    height: 100vh;
    padding-top: 70px;
    background-image: linear-gradient(to bottom, #000000, #000000, #000000f6, #00000000);
  }

  .admin {
    #mobileAdminNavBar {
      position: fixed;
      top: 10px;
      left: 100px;
      z-index: 2;
    }

    #mobileAdminNavDropdown {
      display: none;
      position: fixed;
      left: 0px;
      width: 100vw;
      height: 100px;
      background-color: #000000;
      justify-content: space-evenly;

      &.dropdownOpen {
        display: flex;
      }

      &::before {
        content: "";
        position: absolute;
        top: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: black;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom, #000000, #000000, #00000000);
      }

      a {
        width: 20vw;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }

      img {
        width: 15vw;
      }

      i {
        font-size: 2rem;
        color: white;
      }
    }

    #openNav,
    #closeNav {
      padding: 20px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      background: none;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }
  }
}
</style>
