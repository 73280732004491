import axios from "axios";
const url = "https://api.bookedsolid.co.uk";
//  const url = 'http://localhost:5002';
 const client_id = '651d53c649decc868a3928a6';

/***********  User functions ***********/

// Register a user
async function register(name, email, password, mobile, emergency_contact, referral_source, parq) {
  const data = {
    name,
    email,
    password,
    mobile,
    emergency_contact,
    referral_source,
    parq,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/user/register`, data);
  if (response.status == 201 || response.status == 200) {
    // if successful, add session to localStorage
    localStorage.userSession = response.data.token;
    sessionStorage.userSession = response.data.token;
    localStorage.name = response.data.userDetails.name;
    localStorage.email = response.data.userDetails.email;
    localStorage.newUser = 'true';
    alert('Thank you for registering! A credit has been added to your account so you can try out a Chislehurst Fitness session for free.')
    window.location.href = '/book';
    return response;
  } else return response.data.message;
}

// Login a user
async function login(email, password) {
  const data = {
    email,
    password,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/user/login`, data)
    .catch((err) => { 
      return alert(err?.response?.data?.message || 'Unexpected error.'); 
    });
  if (response.status == 200) {
    // if successful, add session to localStorage
    localStorage.userSession = response.data.token;
    sessionStorage.userSession = response.data.token;
    localStorage.name = response.data.name;
    localStorage.email = response.data.email;
    console.log(response);
    window.location.href = '/book';
    return;
  } else alert(`Login failed. ${response.data.message}`);
}

// Get user's profile
async function getProfile() {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.get(`${url}/user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // return profile if successful
  console.log(response);
  localStorage.newUser = 'false';
  if (response && response.status == 200) {
    return response.data.user;
  } else {
    alert("Unable to get profile. Please login and retry");
    return false;
  }
}

async function updateProfile(updates) {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.put(`${url}/user/update`, { updates }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // return profile if successful
  console.log(response);
  if (response && response.status == 200) {
    return response.data.updatedProfile;
  } else {
    alert("Unable to update profile. Please login and retry");
    return false;
  }
}

async function getBookings() {
  // send request to server
  const authToken = localStorage.userSession;
  const response = await axios.get(`${url}/user/bookings`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  });
  // return bookings if successful
  console.log(response);
  if (response && response.status == 200) {
    return response.data.bookings;
  } else {
    alert("Unable to get bookings. Please login and retry");
    return false;
  }
}

// Request the resetting of a password
async function forgotPassword(email) {
  if (!email) return alert('Please enter your email');
  else {
    const data = {
      email,
      client: client_id
    };
    // send request to server
    const response = await axios.post(`${url}/user/forgot_password`, data).catch((err) => {
      alert(err?.response?.data?.message || 'Unexpected error.');
      return;
    });
    console.log(response);
    if (response && response.status == 200) {
      return alert('Please check your email for instructions');
    }
  }
}

// Reset the password
async function resetPassword(token, new_password, confirm_password) {
  if (!new_password || !confirm_password) return alert('Passwords do not match');
  const data = {
    new_password,
    confirm_password,
    client: client_id
  };
  const response = await axios.post(`${url}/user/reset_password/${token}`, data)
    .catch((err) => {
      console.log(err?.response?.data?.message || 'Error updating password. Please try again later.');
      return false;
    });
  console.log(response);
  if (response?.data?.success === true) return true;
  else {
    console.log('Error updating password. Please try again later.');
    return false;
  }
}

/***********  Bookings functions ***********/

// Get upcoming schedule
async function getAvailability(site, week) {
  const response = await axios.get(`${url}/booking/availability?site=${site}&week=${week}`);
  console.log(response);
  return response.data.availability;
}

// Book (covers all scenarios)
async function bookClass(data) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.post(`/booking`, data);
  console.log(response);
  if (response.data.success === false) alert(response.data.message);
}

// Cancel a booking
async function cancelClass(booking_id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.put(`/booking/cancel/${booking_id}`);
  console.log(response);
  alert(response.data.message);
  if (response?.data?.success === true) return true;
  else return false;
}

// Join waiting list for a bookable
async function joinWaitingList(data) {
  const authToken = localStorage.userSession;
const instance = axios.create({
  baseURL: `${url}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${authToken}`
  },
});
const response = await instance.post(`/booking/waiting-list`, data);
console.log(response);
if (response?.data?.success === true) return true;
else return false;
}

/***********  Payment functions ***********/

async function createCheckout(site_id, price_ids, selection) {
  const data = {
    site_id,
    price_ids,
    selection,
  };
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: url,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.post('/stripe/pay', data);
  console.log(response);
  if (response.status == 200) {
    window.location.href = response.data.url;
  }
}

async function findOffer(id) {
  const response = await axios.get(`${url}/stripe/offer/${id}/${client_id}`);
  if (response?.data?.success === true) {
    return response.data.product;
  } else return false;
}

async function redeemOffer(email, id) {
  const response = await axios.post(`${url}/stripe/offer`, { email, id, client: client_id });
  if (response?.data?.success === true) {
    window.location.href = response.data.url;
  } else if (response?.data?.message) alert(response.data.message);
  else alert('This email address has not be registered with us.');
}

// cancel an active subscription
async function cancelSubscription(credit_id) {
  const authToken = localStorage.userSession;
  const instance = axios.create({
    baseURL: `${url}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${authToken}`
    },
  });
  const response = await instance.put(`/stripe/subscription`, {
    client: client_id,
    credit_id
  });
  console.log(response);
  if (response?.data?.success === true) return true;
  else return false;
}

/***********  General functions ***********/

// Submit a contact form
async function sendContact(name, email, message) {
  const data = {
    name,
    email,
    message,
    client: client_id
  };
  // send request to server
  const response = await axios.post(`${url}/homepage/contact`, data)
    .catch(() => {
      return false;
    });
  console.log(response);
  if (response.status == 200) {
    return true;
  } else return false;
}

export {
  login,
  register,
  getProfile,
  updateProfile,
  getBookings,
  forgotPassword,
  resetPassword,
  getAvailability,
  bookClass,
  cancelClass,
  joinWaitingList,
  createCheckout,
  findOffer,
  redeemOffer,
  cancelSubscription,
  sendContact
};