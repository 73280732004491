<template>
  <div id="home-page">

    <header>
      <h1>Welcome to <br />Chislehurst Fitness</h1>
      <h3>Matt Venner</h3>
      <button v-if="newUser" class="cta" @click="this.$router.push('/book')">Get Free Taster <i
          class="fas fa-long-arrow-alt-right"></i></button>
      <button v-else class="cta" @click="this.$router.push('/book')">Book Now <i
          class="fas fa-long-arrow-alt-right"></i></button>
    </header>

    <section id="home-content">
      <img src="@/assets/CH-homepage-image.png" />

      <div>
        <h2>Chislehurst Fitness</h2>
        <ul>
          <li><i class="fas fa-circle-check"></i> Group outdoor fitness sessions in a supportive and friendly environment
          </li>
          <li><i class="fas fa-circle-check"></i> Boxercise full body workouts</li>
          <li><i class="fas fa-circle-check"></i> Individual personal training and couples sessions</li>
          <li><i class="fas fa-circle-check"></i> All levels of fitness welcome</li>
          <li><i class="fas fa-circle-check"></i> Huge range of workouts and a variety of kit</li>
          <li><i class="fas fa-circle-check"></i> First aid qualified and COVID-19 secure</li>
          <li><i class="fas fa-circle-check"></i> Fully insured by Fit Pro</li>
          <li><i class="fas fa-circle-check"></i> Licensed and approved to use the Walden Recreation Ground by Idverde
          </li>
        </ul>
        <h4>Whoever you are, wherever you are in life, Chislehurst Fitness is for you!​</h4>
        <div>
          <p>
            "My aim is to help you reach your health and fitness goals in a supportive and friendly environment. Let me
            help you improve your body and mind."
          </p>
          <i>Matt Venner</i>
        </div>
      </div>
    </section>

    <section id="home-video">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/2oiHCt5RdBM?si=PBYqMcyUJbHFNvoT"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <div>
        <p>Thrilled to be able to share with you a glimpse into the world of Chislehurst Fitness!
          <br />Great team, great workout, great fun!
        </p>
        <h5>Video Production – Stu Productions </h5>
      </div>
    </section>

    <section id="home-quote">
      <img src="@/assets/quote-icon.png" />
      <div>
        <p>When I first started PT with Matt, I liked to think of myself as having a reasonable level of fitness but I
          wanted to push myself further and have a more varied training regime. PT sessions with him certainly don’t
          disappoint! Leading by example, Matt delivers thought-out and dynamic training plans tailored to the individual
          (he’s always on to me about my posture as an office worker!). If you’re looking for friendly yet intense
          training sessions, I couldn’t recommend Matt highly enough.</p>
        <h3>Steven M</h3>
        <h5>Petts Wood</h5>
      </div>
    </section>

    <section id="home-cta">
      <div id="cta-bg"></div>
      <h2>Let's work together to get you fitter, healthier and happier.</h2>
      <p>Starting a new exercise programme can be daunting but I will strive to make the journey progressive, challenging
        and, most of all, fun!
      </p>
      <button class="cta" @click="this.$router.push('/book')">Book Now <i
          class="fas fa-long-arrow-alt-right"></i></button>
    </section>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      newUser: localStorage.getItem('newUser') !== 'false',
    }
  },
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

header {
  width: 100%;
  height: 100vh;
  padding: 0 10vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url(@/assets/Home_page_header_image-new-desktop.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: left;
  color: white;

  h1 {
    font-size: 67px;
    margin: 0;
  }

  h3 {
    font-size: 36px;
    margin: 20px 0;
  }
}

#home-content {
  display: flex;

  img {
    width: 45%;
    object-fit: cover;
    margin: 60px 20px;
  }

  div {
    text-align: left;
    margin-top: 23px;
    margin-left: 30px;

    h2 {
      font-size: 45px;
      border-left: solid 2px $colourP;
      color: #7a7a7a;
      padding: 10px 15px;
    }

    ul {
      padding: 0;

      li {
        font-size: 15px;
        line-height: 27.8px;
        padding: 4.5px 0;
        list-style: none;
        display: flex;
        align-items: center;

        i {
          font-size: 21px;
          margin-right: 10px;
          color: $colourP;
        }
      }
    }

    h4 {
      font-size: 20px;
      color: $colourP;
      letter-spacing: normal;
      width: 90%;
    }

    div {
      background: $colourP;
      margin-left: -50px;
      width: 100%;
      padding: 50px;
      box-sizing: border-box;
      color: white;
      font-size: 19px;
    }
  }
}

#home-video {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colourP;
  color: white;
  padding: 160px 0;

  div {
    width: 40%;
    margin-left: 30px;
    text-align: left;

    p {
      font-size: 1.2rem;
      line-height: 2.6rem;
      font-style: italic;
    }

    h5 {
      margin-top: 3rem;
      text-transform: uppercase;
    }
  }
}

#home-quote {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: $colourS;
  color: white;
  padding: 160px 0;

  img {
    width: 15%;
    max-width: 200px;
    margin-top: -20px;
  }

  div {
    width: 65%;
    border-left: solid 2px $colourP;
    padding: 0 40px;
    margin: 0 20px;
    text-align: left;

    p {
      font-size: 1.1rem;
      line-height: 2.6rem;
      font-style: italic;
      margin: 0;
      letter-spacing: 2px;
    }

    h3 {
      font-size: 1.8rem;
      margin: 0;
      color: $colourP;
      font-weight: normal;
      margin-top: 1.6em;
    }

    h5 {
      margin: 0;
      margin-top: 20px;
      text-transform: uppercase;
    }
  }
}

#home-cta {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('@/assets/homepage-background-image-779-copy.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  padding: 160px 20px;
  box-sizing: border-box;

  h2 {
    font-size: 45px;
    letter-spacing: normal;
  }

  p {
    font-size: 15px;
  }
}

.cta {
    background: #0000009C;
    padding: 20px 55px;
    border: solid 2px white;
    color: $colourP;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 50px;
    cursor: pointer;

    i {
      margin-left: 10px;
      font-size: 14px;
    }

    &:hover {
      border-color: $colourP;
    }
  }

@media screen and (max-width: 900px) {
  header {
    padding: 0;
    height: 420px;
    background-image: url(@/assets/Home-page-header-mobile-copy.png);
    background-position: bottom;
    background-attachment: unset;
    text-align: center;

    h1 {
      font-size: 37px;
      width: 100%;
      margin-top: 180px;
    }

    h3 {
      font-size: 22px;
      width: 100%;
    }

    .cta {
      margin: auto;
      margin-top: unset;
    }
  }

  #home-content {
    display: flex;

    img {
      display: none;
    }

    div {
      margin: 20px;

      h2 {
        font-size: 31px;
        text-align: center;
      }

      ul {
        li {
          font-size: 13.68px;
        }
      }

      h4 {
        font-size: 18px;
        text-align: center;
        width: 100%;
      }

      div {
        margin-left: 0px;
        line-height: 1.5;
      }
    }
  }

  #home-video {
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 50px 10px;

    iframe {
      width: 100%;
    }

    div {
      width: 100%;

      p {
        font-size: 16px;
        line-height: 1.5rem;
      }

      h5 {
        font-size: 12px;
      }
    }
  }

  #home-quote {
    flex-direction: column;
    padding: 50px 0;

    img {
      width: 60%;
      max-width: unset;
      margin: auto;
    }

    div {
      width: calc(100% - 40px);
      padding-left: 40px;
      margin-top: 20px;
      text-align: left;
      box-sizing: border-box;

      p {
        font-size: 16px;
        line-height: 1.5;
        padding: 0;
        box-sizing: border-box;
      }

      h3 {
        font-size: 1.7rem;
      }
    }
  }

  #home-cta {
    padding: 50px 10px;
  }
}
</style>